<template>
    <div>
        <div class="page">
            <van-nav-bar title="企业内刊" left-text="返回" left-arrow @click-left="back"></van-nav-bar>
            <van-cell>
                <template #title>
                    <div>
                    <van-icon name="bookmark-o" /><span style="margin-left:5px;color:#000000;">{{item.name}}</span>
                    </div>
                    <div>
                    <van-icon name="clock-o" /><span style="margin-left:5px;">发布日期: {{item.publishTime}}</span>
                    </div>
                    <div>
                    <span style="color:#999;">{{item.introduce}}</span>
                    </div>
                </template>
            </van-cell>
            <div class="pdfContainer" v-if="item.files != null"  :style="{'display': item.type === 2 || !item.type ? 'block':'none'}">
                <div class="pdfItem" v-for="oneFile in JSON.parse(item.files)" :key = "oneFile.$index">
                    
                    <div v-if="oneFile.fileName.endWith('.pdf')">
                    <van-image :src="bookSrc"  @click="viewFile(oneFile)" />
                    </div>
                    <viewer v-else :images="[{url:oneFile.serverFname,title:'图片查看'}]">
                        <img v-for="src in [{url:oneFile.serverFname,title:'图片查看'}]" :src="src.url" :key="src.title" 
                            style="width:100%;">
                    </viewer>
                    
                    <div v-if="oneFile.fileName.endWith('.pdf')"><span>{{oneFile.fileName}}</span></div>
                    <div v-if="oneFile.fileName.endWith('.pdf')"><van-button @click="viewFile(oneFile)" type="primary" style="margin-top:7px;">查看</van-button></div>
                </div>
            </div>
            <!-- <van-overlay :show="show" @click="show = false"> -->
            <!-- <div class="wrapper" @click.stop>
                <van-image :src="imgSrc" ref="pic" id="pic" />
                <van-image :src="closeSrc" style="position:absolute;right:10px;top:10px;width:48px;height:48px;" @click="show = false"/> 
                <viewer :images="imgSrc">
                <img v-for="src in imgSrc" :src="src.url" :key="src.title">
                </viewer>
            </div> -->
            
            <!-- </van-overlay> -->
            
        </div>
        <div v-html="item.html" class="tinymce-edit" :style="{'display': item.type === 1 ? 'block':'none'}"></div>
    </div>
</template>

<script>
// import preview from 'vue-photo-preview'
// import 'vue-photo-preview/dist/skin.css'
import Vue from "vue";
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer);
Viewer.setDefaults({
  Options: { "inline": true, "button": true, "navbar": true, "title": true, "toolbar": true, "tooltip": true, "movable": true, "zoomable": true, "rotatable": true, "scalable": true, "transition": true, "fullscreen": true, "keyboard": true, "url": "data-source" }
});
// Vue.use(preview)
//自定义
String.prototype.endWith=function(oString){  
  var   reg=new   RegExp(oString+"$");  
  return   reg.test(this);     
}  
    export default {
        data() {
            return {
                scale: 100, //放大系数
                idx: -1,
                imgSrc:null,
                show:false,
                item:{
                    name:null,
                    files:null,
                    publishTime:null,
                },
                bookSrc:require('../../assets/img/pdf.png'),
                closeSrc:require('../../assets/img/close.png'),
            };
        },
        methods: {
            scaleD() {
                this.scale += 30;
                console.log(this.scale);    
                // this.$refs.pic.$pic.style.width = parseInt(this.scale) + "%";
                //  document.getElementById("pic").style.width = parseInt(this.scale) + "%";
            },
        
            //缩小
            scaleX() {
                if (this.scale == 100) {
                 return;
                }
                this.scale += -30;
                console.log(this.scale);
                document.getElementById("pic").style.width = parseInt(this.scale) + "%";
                // this.$refs.pic.$pic.style.width = parseInt(this.scale) + "%";
            },
            viewFile(oneFile) {
                if (oneFile.serverFname.endWith('.pdf')) {
                    this.$router.push({name:'pdfview', query:oneFile});
                } else {
                    this.show = true;
                    this.imgSrc =[{url:oneFile.serverFname, title:'图片预览'}];
                }
            },
            getDetail() {
                this.$axios.get("/article/getArticle", {params:{id:this.$route.params.id}})
                .then(res => {
                    if(res.code == "ok") {
                        this.item = res.data;
                    } 
                }).catch(err=> {});
            },
           
            back() {
                this.$back();
            },
        },
        mounted() {
            this.getDetail();
            setTimeout(() => {
                this.$previewRefresh()
            }, 100);
        }
    };
</script>

<style lang="less" scoped>
    .logout {
        bottom: 0;
        height: 50px;
        color: #ff5f16;
    }

    /* 本页公共样式 */
    .gray {
        color: #797d82;
    }

    .bott0 {
        bottom: 0;
    }

    .orange {
        color: #ff5f16
    }

    .f15 {
        font-size: 15px;
    }

    .bd-gray {
        border-bottom: 1px solid #f5f5f5;
    }

    // 设置
    header {
        .city {
            height: 44px;
            line-height: 44px;
        }
    }

    // 账号ID
    .id {
        height: 49px;
        line-height: 49px;

    }

    .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .block {
    width: 120px;
    height: 120px;
    background-color: #fff;
  }
</style>
<style lang="less">
    .pdfContainer {
        margin-top:10px;
        background:#ffffff;
        text-align:center;
        padding:10px;
    }
    .pdfItem {
        margin-top:10px;
    }
    .control_bar {
        float:right;
        position:fixed;
        bottom:80px;
        right:0px;
        margin-right:3px; 
        background:#ddd;
        opacity:0.4;
        filter:alpha(opacity=40); 
        border-radius:3px;
        padding:3px;
    }
    .tinymce-edit {
        padding: 10px;
        line-height: 1.4;
        overflow: hidden;
        background-color: #fff;
        h1{ font-size:2em; margin: .67em 0 }
        h2{ font-size:1.5em; margin: .75em 0 }
        h3{ font-size:1.17em; margin: .83em 0 }
        h4, p,blockquote, ul,fieldset, form,ol, dl, dir,menu { margin: 1.12em 0}
        h5 { font-size:.83em; margin: 1.5em 0 }
        h6{ font-size:.75em; margin: 1.67em 0 }
        h1, h2, h3, h4,h5, h6, b,strong  { font-weight: bolder ;line-height: 1.2;}
        a:link {color: blue} 
        a:visited {color: blue}
        a:hover {color: blue}
        a:active {color: blue} 
        // p {margin: 1em 0;}
        p {margin: 0;}
        img {max-width: 100%; height: auto;}
        iframe{ width: 100%; height: 56vw;}
    }
</style>

